import { Layout } from "../components/layout/Layout";

export default Layout;

# The Garden 🌱

## 🏡 Where am I?

Hey there! Welcome to my digital garden 🌱🍄🌼

I'm Michael Dinerstein
([@missionmikedev](https://www.twitter.com/missionmikedev) on Twitter), a
developer and designer always eager to learn more. You've landed in a place
where I experiment, write, document, and grow ideas. Some are old ideas,
probably familiar. Others are new, maybe strange, or just for fun.

---

## ♾️ Learning is Infinite

Regardless how many years of experience one has, how many projects were built,
or how "successful" in life, there will _always_ be room to improve.

Similar to how [Moore's Law](https://en.wikipedia.org/wiki/Moore%27s_law)
applies to transistors, it's reasonable to expect that a similar law accounts
for the regular increase in available software libraries, frameworks, and new
invention. As such, it's important to remember that **it's impossible to know it
all, _ever._** While learning one subject, _countless_ new things are being
invented.

Here I'll be studying concepts new to me, sharing my studies and growing in
public.

Check the nav menu to explore. Cheers!
